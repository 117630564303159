import { FontSize } from './types';

export function getFontSizeCSS({ fontSize }: { fontSize: FontSize }): 'inherit' | number {
  switch (fontSize) {
    case 'inherit':
      return 'inherit';
    case 'medium':
      return 20;
    case 'small':
      return 16;
    case 'large':
      return 24;
  }
}
