import type { Theme, SxProps } from '@mui/material/styles';
import type { SystemStyleObject } from '@mui/system/styleFunctionSx';

export { useTheme, lighten, darken, styled, alpha } from '@mui/material/styles';

export * from './globalTheme';
export * from './useBreakpointsMatch';
export * from './colors';
export * from './types';
export * from './getFontSizeCSS';

export type { Theme, SxProps, SystemStyleObject };
